import React from "react";
import Button from "@components/button";
import Container from "@components/container";
import Link from "@components/link";
import LinkedInIcon from "@/icons/linkedin";
import Logo from "@/layouts/partials/logo";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => (
  <header id="top">
    <Container className="flex items-center justify-between font-medium py-md text-base-fluid">
      <Link to="/" className="rounded">
        <Logo aria-hidden="true" />
        <span className="sr-only">Airline Strategy Group</span>
      </Link>
      <nav className="flex justify-end flex-1 sr-only xs:not-sr-only">
        <ul className="flex items-center gap-md">
          <li className="hidden lg:block">
            <Link to="/#solutions" className="rounded">
              Solutions
            </Link>
          </li>
          <li className="hidden lg:block">
            <Link to="/#people" className="rounded">
              Who we are
            </Link>
          </li>
          <li className="hidden sm:block">
            <Button to="/#contact">
              <span className="hidden sm:inline">Get in touch</span>
              <span className="sm:hidden">Contact</span>
            </Button>
          </li>
        </ul>
      </nav>
      <div className="hidden xs:items-center xs:flex xs:ml-sm lg:ml-base">
        <Link
          to="https://www.linkedin.com/company/airline-strategy-group/"
          className="rounded p-1 transition duration-300 ease-out hover:text-[#0a66c2] dark:hover:text-white"
          title="LinkedIn"
        >
          <LinkedInIcon aria-hidden="true" />
          <span className="sr-only">Visit our LinkedIn profile</span>
        </Link>
      </div>
    </Container>
  </header>
);

export default Header;
