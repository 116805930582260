module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Airline Strategy Group","short_name":"Airline Strategy","description":"Guidance through the turbulence","start_url":"/","background_color":"#f5f8ef","theme_color":"#f5f8ef","icon":"resources/images/icon.png","icons":[{"src":"/favicons/asg-icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/favicons/asg-icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/favicons/asg-icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/favicons/asg-icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/favicons/asg-icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicons/asg-icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/favicons/asg-icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5555289848c584cf525d635305580f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/default"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
