import React from "react";

const Icon: React.FC<
  Omit<React.SVGAttributes<SVGElement>, "stroke"> & {
    stroke?: boolean | number;
    size?: number;
  }
> = ({ stroke = false, size = 16, children = undefined, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    {...props}
  >
    <g
      fill={stroke ? "none" : "currentColor"}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={!stroke ? "none" : "currentColor"}
      strokeWidth={
        stroke ? (typeof stroke === "number" ? stroke : 1.5) : undefined
      }
    >
      {children}
    </g>
  </svg>
);

export default Icon;
