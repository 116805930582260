import { cn } from "@/helpers/classNames";
import * as React from "react";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  el?: "div" | "section" | "aside" | "header";
  bleed?: boolean;
  full?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  el = "div",
  bleed,
  children,
  className,
  ...props
}) => {
  const Wrapper = el;

  return (
    <Wrapper
      className={cn(
        "container",
        bleed ? "!px-gutter lg:!px-gutter-2x max:!px-gutter-2x" : "",
        className
      )}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
