import React, { useEffect, useMemo, useContext } from "react";

export interface ThemeContextProps {}

const ThemeContext = React.createContext<ThemeContextProps>({});

const ThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const value = useMemo(() => ({}), []);

  useEffect(() => {
    if (!document.head.querySelector("meta[name='theme-color']")) {
      const meta = document.createElement("meta");

      meta.setAttribute("name", "theme-color");

      document.head.appendChild(meta);
    }

    const updateThemeColor = (dark: boolean) => document.head
      .querySelector("meta[name='theme-color']")
      ?.setAttribute("content", dark ? "#1b2941" : "#f5f8ef");

    const darkModePreference = window.matchMedia("(prefers-color-scheme: dark)");
    const listener = e => updateThemeColor(e.matches);

    darkModePreference.addEventListener("change", listener);
   
    updateThemeColor(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);

    return () => {
      darkModePreference.removeEventListener("change", listener);

      document.head.querySelector("meta[name='theme-color']")?.remove();
    }
  }, []);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };

export const useTheme = () => useContext(ThemeContext);
