import Container from "@components/container";
import React from "react";
import Marquee from "react-fast-marquee";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => (
  <footer className="font-medium text-base-fluid pt-lg pb-base offscreen">
    <Container className="grid pb-md gap-xs lg:grid-cols-4 lg:grid-gutter-2x">
      <div>All Rights Reserved.</div>
      <div className="lg:col-span-2">
        © 2021-{new Date().getFullYear()} Airline Strategy Group, Inc.
      </div>
      <div>
        <a href="mailto:info@airline-strategy.com" className="hover-underline">
          info@airline-strategy.com
        </a>
      </div>
    </Container>
    <Marquee aria-hidden="true">
      <div
        className="flex tracking-tighter select-none text-3xl-fluid gap-2f py-1f"
        role="presentation"
      >
        <div className="font-bold">Airline Strategy Group</div>
        <div className="mr-8f">
          Guidance through the turbulence
          <sup className="text-[0.25em] align-super">TM</sup>
        </div>
      </div>
    </Marquee>
  </footer>
);

export default Footer;
