import { cn } from "@/helpers/classNames";
import * as React from "react";
import Link, { LinkProps } from "@components/link";

export type ButtonProps = {
  to?: LinkProps["to"];
  size?: "base" | "sm";
  style?: "default" | "secondary";
  icon?: React.JSXElementConstructor<React.HtmlHTMLAttributes<SVGElement>>;
  iconPosition?: "before" | "after";
} & (React.HTMLAttributes<HTMLButtonElement> | Omit<LinkProps, "to" | "type">);

const Button: React.FC<ButtonProps> = ({
  to,
  icon,
  iconPosition = "before",
  size = "base",
  style = "default",
  children: passedChildren,
  className,
  ...passedProps
}) => {
  const Icon = icon;

  const props = {
    className: cn(
      "group/button will-change-transform leading-[1.2] inline-flex rounded-full transition ease-in-out duration-200 hover:duration-500 hover:scale-x-105 shadow-[0_0.25em_1em_rgba(0,0,0,0.05)] hover:shadow-[0_0.35em_1.25em_rgba(0,0,0,0.1)]",
      size === "sm" ? "py-1f px-2f" : "py-2f px-3f",
      style === "default"
        ? "bg-mirage-800 text-sand-100 dark:bg-sand-100 dark:text-mirage-800 hover:bg-seabury dark:hover:bg-sand-100 dark:hover:text-seabury"
        : "bg-white text-mirage-800 dark:bg-mirage-700 dark:text-sand-100",
      className
    ),
    ...passedProps,
  };

  const IconWrapper = Icon && (
    <span aria-hidden="true">{<Icon className="w-4f h-4f" />}</span>
  );

  const children = (
    <span className="inline-flex shrink-0 will-change-transform items-center transition ease-in-out gap-xs duration-200 group-hover/button:duration-500 group-hover/button:scale-x-[0.9523]">
      {iconPosition === "before" && IconWrapper}
      {passedChildren && <span>{passedChildren}</span>}
      {iconPosition === "after" && IconWrapper}
    </span>
  );

  if (to) {
    return (
      <Link to={to} {...(props as Omit<LinkProps, "to">)}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      {...(props as React.HTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

export default Button;
